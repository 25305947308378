<template>
  <section class="dishes">
    <Dishes />
  </section>
</template>

<script>
import Dishes from '@/components/Dishes.vue'

export default {
  name: 'DishesView',
  components: {
    Dishes
  }
}
</script>

<style>
 .nowrap {
  white-space: nowrap;
}
</style>
